<template>
    <div class="setup">
        <div class="panel" v-for="groups in sysProjectGroups" :key="groups">
            <div class="title">{{groups.title}}</div>
            <div class="list-box clearfix">
                <div class="li" v-for="item in groups.children" :key="item" @click="go(item)">
                    <div class="icon"><i class="iconfont" :class="item.icon"></i></div>
                    <div class="name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <!-- 日结 -->
        <modal-load :isShow="dayEndIshow">
            <day-end-model :isShow="dayEndIshow" v-on:closeModel="dayEndIshow=false" v-on:confirm="dayEndIshow=false"></day-end-model>
        </modal-load>
        <!-- 结班 -->
        <modal-load :isShow="shiftCompletionIshow">
            <shift-completion-model :isShow="shiftCompletionIshow" v-on:closeModel="shiftCompletionIshow=false" v-on:confirm="shiftCompletionIshow=false"></shift-completion-model>
        </modal-load>
        <!-- 打印监控服务 -->
        <modal-load :isShow="printMonitorIshow" :isUnload="true">
            <pos-print-monitor-model :isShow="printMonitorIshow" v-on:closeModel="printMonitorIshow=false" ></pos-print-monitor-model>
        </modal-load>
        <!-- 配菜单 -->
        <modal-load :isShow="withMenuIshow" >
            <with-menu-model :isShow="withMenuIshow" v-on:closeModel="withMenuIshow=false" ></with-menu-model>
        </modal-load>
         <!-- 押金管理 -->
        <modal-load :isShow="preMoneyIshow" :isUnload="true">
            <save-pre-money-model :isShow="preMoneyIshow" v-on:closeModel="preMoneyIshow=false" ></save-pre-money-model>
        </modal-load>
        <!-- 手工登记开票 -->
        <modal-load :isShow="invoicingIsShow" isUnload>
            <custom-invoicing-model :isShow="invoicingIsShow" @close="invoicingIsShow=false"/>
        </modal-load>
    </div>
</template>

<script>

import dayEndModel from './dayEndModel/dayEndModel.vue';
import shiftCompletionModel from './shiftCompletionModel/shiftCompletionModel.vue'
import posPrintMonitorModel from './posPrintMonitorModel/posPrintMonitorModel.vue'
import withMenuModel from '/src/components/home/diningTable/diningDetail/withMenuModel/withMenuModel.vue';
import savePreMoneyModel from './savePreMoneyModel/savePreMoneyModel.vue'
import customInvoicingModel from './customInvoicingModel/customInvoicingModel.vue'

export default { 
    components:{
        dayEndModel,
        shiftCompletionModel,
        posPrintMonitorModel,
        withMenuModel,
        savePreMoneyModel,
        customInvoicingModel
    },
    name:'setup',
    data(){
        return{
            listData:[
                {key:'ShiftCompletion',name:'结班',icon:'icon-jieban'},
                {key:'DayEnd',name:'日结',icon:'icon-rijie'},
                {key:'ARMONEY',name:'应收账户处理',icon:'icon-jieban',url:"corpARMoney"},
                {key:'SoldOutConfig',name:'沽清设置',icon:'icon-guqing',url:'sellOutSetUp'},
                {key:'FoodPrintSet',name:'菜品出单设置',icon:'icon-caipinchudanshezhi',url:"eatKitPlaceSet"},
                {key:'DZC_StandNum',name:'海鲜标准重量',icon:'icon-caipinchudanshezhi',url:'DZC_StandNum'},
                {key:'SiteSet',name:'站点配置',icon:'icon-zhandianpeizhi',url:'siteSet'},
                {key:'PrintModuleSet',name:'打印模板设置',icon:'icon-a-dayinmoban1',url:'printModuleSet'},
                {key:'UserFormDefined',name:'界面布局设置',icon:'icon-yemianbuju',url:'userFormDefined'},
                {key:'ChangePwd',name:'用户密码修改',icon:'icon-icon_xiugaimima',url:'changePwd'},
                {key:'SysSet',name:'POS业务开关',icon:'icon-posyewukaiguan',url:'sysSet'},
                {key:'PosPrintSet',name:'站点打印机分配',icon:'icon-zhandiandayinjifenpei',url:'posPrintSet'},
                {key:'OnlinePrintSet',name:'线上打印机分配',icon:'icon-xianshangdayinjifenpei',url:'onlinePrintSet'},
                {key:'PrintMonitor',name:'打印监控服务',icon:'icon-zhandiandayinjifenpei'},
                {key:'SavePeiCai',name:'配菜单',icon:'icon-peicaidan'},
                {key:'SavePreMoney',name:'押金管理',icon:'icon-a-yajinguanli3x'},
                {key:'SaveKitName',name:'厨师信息设置',icon:'icon-chushi',url:"saveKitNameSet"},
                {key:'G10POSTranData',name:'账单标签',icon:'icon-jieban',url:'g10POSTranData'},
                {key:'EATBINDCARD',name:'菜品绑定卡号',icon:'icon-zhandianpeizhi--fenlei',url:'eatBindCard'},
                {key:'EATXFBMUPDOWN',name:'菜品上下架',icon:'icon-peicaidan',url:'eatXfbmupdown'},
                {key:'EATXFBPRICEUPDATE',name:'时价菜设置',icon:'icon-peicaidan',url:'eatXfbPiceUpdate'},
                {key:'Eat_XFBMReplaceRatio',name:'主副菜关联设置',icon:'icon-peicaidan',url:'eatXfbmReplaceRatio'},
                {key:'CustomInvoicing',name:'手工开票登记',icon:'icon-taocanxiaoshoubaobiao'},
                {key:'EmergencyEatXFBM',name:'急推菜品设置',icon:'icon-zhandianpeizhi',url:'emergencyEatXfbm'}
            ],
            sysProjectGroups:[],
            /**日结 */
            dayEndIshow:false,
            /**结班 */
            shiftCompletionIshow:false,
            /**打印监控服务 */
            printMonitorIshow:false,
            /**配菜单 */
            withMenuIshow:false,
            /**押金管理 */
            preMoneyIshow:false,
            /**手工登记开票 */
            invoicingIsShow:false,
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.$nextTick(()=>{
            let groups=[];
            /**报表菜单数据 */
            this.$cacheData.SysProjects?.get()?.find(t=>t.Project_Key=="Config")
            ?.SysProjectGroups.find(t=>t.Group_Key=='BaseSet_CloudPos' && t.SubGroup_YN)
            ?.SysProjectItems?.forEach(item => {
                if(item.Is_Show){
                    let children=[];
                    item.SysProjectSubItems.forEach((subItem)=>{//二级菜单
                        if(subItem.Is_Show){
                            this.listData.find((childreNav)=>{
                                if(childreNav.key==subItem.ProjectSubItem_Key){
                                    let data=Object.assign({},childreNav,{title:subItem.ProjectSubItem_Name});
                                    children.push(data);
                                    return true;
                                }
                                return false;
                            })
                        }
                    })
                    groups.push({
                        title:item.ProjectItem_Name,
                        children:children
                    })
                }
            });
            this.sysProjectGroups=groups;
        });
    },
    methods:{
        go(item){
            if(item.url){
                this.$router.push(item.url);
            }else if(item.key=="ShiftCompletion"){//结班
                this.shiftCompletionIshow=true;
            }else if(item.key=="DayEnd"){//日结
                this.dayEndIshow=true;
            }else if(item.key=="PrintMonitor"){//打印监控服务
                this.printMonitorIshow=true;
            }else if(item.key=="SavePeiCai"){//配菜单
                this.withMenuIshow=true;
            }else if(item.key=="SavePreMoney"){//押金管理
                this.preMoneyIshow=true;
            }else if(item.key=="CustomInvoicing"){//手工开票登记
                this.invoicingIsShow=true;
            }
        }
    }
}
</script>

<style lang="scss">
@import './setup.scss';
</style>